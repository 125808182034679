<template>
  <div id="saleRefSearch-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="background-color: white;">
              <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel2">Filter</h6>
                <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body" style="overflow-y: visible;">
                <div class="row">
                  <div class="col-md-3" v-if="selectedSearchInput.agent">
                    <div class="form-group">
                      <label for="section_id" class="form-control-label">Agent</label>
                      <v-select id="search2" name="search" placeholder="Agent" label="name" :options="agentList"
                        v-model="selectedAgent">
                        <option v-for="user in agentList" :key="user.name" :value="user.id">
                          {{ user.name }}
                        </option>
                      </v-select>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.sale_id">
                    <div class="form-group">
                      <label for="section_id" class="form-control-label">Section ID</label>
                      <input v-model="section_id" type="search" id="form1" name="section_id_search"
                        search-id="section_id_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.quotation_id">
                    <div class="form-group">
                      <label for="quotation_id" class="form-control-label">Quotation ID</label>
                      <input v-model="quotation_id" type="search" id="form1" name="quotation_id_search"
                        search-id="quotation_id_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.lead_id">
                    <div class="form-group">
                      <label for="Lead" class="form-control-label">Lead Number</label>
                      <input v-model="Lead" type="search" id="form1" name="lead_search" search-id="lead_search"
                        class="form-control" style="margin-top: 0px; width: 100%; height: 2rem"
                        placeholder="Search here" aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.sale_reference_id">
                    <div class="form-group">
                      <label for="sale_reference_id" class="form-control-label">Sale Number</label>
                      <input v-model="sale_reference_id" type="search" id="form1" name="Sale_number_search"
                        search-id="Sale_number_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.client_name">
                    <div class="form-group">
                      <label for="Client_name" class="form-control-label">Client Name</label>
                      <input v-model="Client_name" type="search" id="form1" name="Client_name_search"
                        search-id="Client_name_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.client_email">
                    <div class="form-group">
                      <label for="client_email" class="form-control-label">Client Email</label>
                      <input v-model="client_email" type="search" id="form1" name="client_email_search"
                        search-id="client_email_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.client_phone">
                    <div class="form-group">
                      <label for="client_phone" class="form-control-label">Client Phone</label>
                      <input v-model="client_phone" type="search" id="form1" name="client_phone_search"
                        search-id="client_phone_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.passenger">
                    <div class="form-group">
                      <label for="Passenger_name" class="form-control-label">Passenger Name</label>
                      <input v-model="Passenger_name" type="search" id="form1" name="Passenger_name_search"
                        search-id="Passenger_name_search" class="form-control"
                        style="margin-top: 0px; width: 100%; height: 2rem" placeholder="Search here"
                        aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.pnr">
                    <div class="form-group">
                      <label for="pnr" class="form-control-label">PNR</label>
                      <input v-model="pnr" type="search" id="form1" name="pnr_search" search-id="pnr_search"
                        class="form-control" style="margin-top: 0px; width: 100%; height: 2rem"
                        placeholder="Search here" aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.acn">
                    <div class="form-group">
                      <label for="ACN" class="form-control-label">Airlines Confirmation Number</label>
                      <input v-model="ACN" type="search" id="form1" name="ACN_search" search-id="ACN_search"
                        class="form-control" style="margin-top: 0px; width: 100%; height: 2rem"
                        placeholder="Search here" aria-label="Search" />
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.from">
                    <div class="form-group">
                      <label for="from_date" class="form-control-label">From Date</label>
                      <date-picker class=" " v-model="saleDateFrom" placeholder="Date Sale From" valueType="YYYY-MM-DD"
                        :editable="false" :format="datePickerFormat" style="
                                height: 48px;
                                width: 100%;
                                border-radius: 8px;
                              "></date-picker>
                    </div>
                  </div>
                  <div class="col-md-3" v-if="selectedSearchInput.to">
                    <div class="form-group">
                      <label for="to_date" class="form-control-label">To Date</label>
                      <date-picker class="" v-model="saleDateTo" :disabled="isSaleDateToDisabled"
                        placeholder="Date Sale To" valueType="YYYY-MM-DD" :editable="false" :format="datePickerFormat"
                        style="
                                height: 48px;
                                width: 100%;
                                border-radius: 8px;
                              "></date-picker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="resetForm">Reset</button>
                <button type="button" class="btn btn-primary" @click="SearchData">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: 'saleRefSearch-modal',
  data: () => ({
    agentList: [],
    selectedAgent: "",
    section_id: "",
    Lead: "",
    sale_reference_id: "",
    quotation_id: "",
    Client_name: "",
    client_email: "",
    client_phone: "",
    Passenger_name: "",
    pnr: "",
    ACN: "",
    saleDateFrom: "",
    saleDateTo: "",
    datePickerFormat: "YYYY-MMM-DD",
  }),
  components: {
    "date-picker": DatePicker,
  },
  props: {
    getAll: {
      type: Function,
      required: true,
    },
    selectedSearchInput: {},
    selectedData: {
      type: Object,
      default: () => ({}), // Default to empty object if no data is passed
    },
  },
  methods: {
    resetForm() {
      let _vm = this;
      _vm.selectedAgent = "";
      _vm.section_id = "";
      _vm.quotation_id = "";
      _vm.Lead = "";
      _vm.sale_reference_id = "";
      _vm.Client_name = "";
      _vm.client_email = "";
      _vm.client_phone = "";
      _vm.Passenger_name = "";
      _vm.pnr = "";
      _vm.ACN = "";
      _vm.saleDateFrom = "";
      _vm.saleDateTo = "";
    },
    getTeamWiseAgentDetail() {
      const _vm = this;
      this.axios
        .get("/team-wise-user")
        .then(function (response) {
          _vm.agentList = response.data.data;
          if (_vm.selectedData && _vm.selectedData.agent_id) {
            _vm.selectedAgent = _vm.agentList.find(agent => agent.id === _vm.selectedData.agent_id) || null;
          }
        })
        .catch(function () { });
    },
    SearchData() {
      this.$emit('search', {
        agent_id: this.selectedAgent.id,
        section_id: this.section_id,
        quotation_id: this.quotation_id,
        Lead: this.Lead,
        sale_reference_id: this.sale_reference_id,
        Client_name: this.Client_name,
        client_email: this.client_email,
        client_phone: this.client_phone,
        Passenger_name: this.Passenger_name,
        pnr: this.pnr,
        ACN: this.ACN,
        saleDateFrom: this.saleDateFrom,
        saleDateTo: this.saleDateTo
      });
    },

  },
  computed: {
    isSaleDateToDisabled() {
      return this.saleDateFrom === "" || this.saleDateFrom === null;
    },

  },
  mounted() {
    this.getTeamWiseAgentDetail();
    if (this.selectedData) {
      this.selectedAgent = this.selectedData.agent_id || "";
      this.section_id = this.selectedData.section_id || "";
      this.Lead = this.selectedData.Lead || "";
      this.sale_reference_id = this.selectedData.sale_reference_id || "";
      this.quotation_id = this.selectedData.quotation_id || "";
      this.Client_name = this.selectedData.Client_name || "";
      this.client_email = this.selectedData.client_email || "";
      this.client_phone = this.selectedData.client_phone || "";
      this.Passenger_name = this.selectedData.Passenger_name || "";
      this.pnr = this.selectedData.pnr || "";
      this.ACN = this.selectedData.ACN || "";
      this.saleDateFrom = this.selectedData.saleDateFrom || "";
      this.saleDateTo = this.selectedData.saleDateTo || "";
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

/* .modal-body {
  max-height: calc(100vh - 180px);
   overflow-y: auto;
} */

.mx-datepicker-popup {
  z-index: 9999 !important;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input[type="text"] {
  padding-right: 50px;
  /* Adjust as needed */
}

.input-with-icon i {
  position: absolute;
  right: 10px;
  /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.x {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5%;
}

.body-height {
  height: auto;
  overflow-x: hidden;
}

.mx-datepicker-popup {
  z-index: 9999 !important;
}
</style>
