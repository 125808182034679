<template>
  <div v-if="billingInformations.billing_methods[0]">
    <div id="billing-info">
      <div class="col-md-12 ">
        <div class="row secondary p-1 mb-1">
          <div class="col-md-3">
            <h5 class="title d-inline-block text-white">
              Billing Information
            </h5>
          </div>
          <div class="col-md-9">
            <ul class="nav nav-pills mb-1 float-right" id="pills-tab" role="tablist">
              <!-- <li class="nav-item">
                <button class=" mr-1 float-right mt-1 payment btn btn-info " id="payment-details" data-toggle="modal"
                  data-target="#paymet_detail" aria-selected="false">Payment Details</button>
              </li> -->
              <li class="nav-item" @click="selectedTab = 'two'">
                <b-badge class=" mr-1 float-right mt-1 nav-link  " id="pills-home-tab"
                  v-if="billingInformations.billing_methods[1]" data-toggle="pill" href="#pills-home" role="tab"
                  aria-controls="pills-home" aria-selected="true">2nd Card</b-badge>
              </li>
              <li class="nav-item" @click="selectedTab = 'one'">
                <b-badge class=" mr-1 float-right mt-1  nav-link active" id="pills-profile-tab" data-toggle="pill"
                  href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">1st
                  Card</b-badge>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="iq-card-body pt-0 "> -->
    <div class="tab-content p-0" id="pills-tabContent-2">
      <div class="tab-pane fade  col-md-12" :class="selectedTab == 'one' ? 'show active' : ''"
        v-if="billingInformations && billingInformations.billing_methods && billingInformations.billing_methods[0]"
        id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <div class="row p-0" ref="ccdetails">
          <div class="col-md-4 bg-light" style="border-radius: 8px;">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-0 mt-1">
                  <label class="small">{{
    billingInformations.billing_methods[0].card_type }} :</label>
                  <span class="ml-2 small  mr-1" ref="creditCardNumber">{{
    maskSensitiveInfo(billingInformations.billing_methods[0].card_number, 0) }}</span><i
                    v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                    @click="copyText(billingInformations.billing_methods[0].card_number)" v-if="!isMasked"></i>
                  <span class="ml-2 small  cursor-pointer">{{
    billingInformations.billing_methods[0].expired_date }}</span>
                  <span class="ml-2 small  cursor-pointer">{{
    isMasked ? maskData(billingInformations.billing_methods[0].cvv) :
      billingInformations.billing_methods[0].cvv }}</span>
                  <span v-if="isCreditCardPermission">
                    <i v-if="!isMasked" class="fa-solid fa-eye-slash fa-lg cursor-pointer float-right mt-1"
                      @click="toggleMask"></i>
                    <i v-else class="fa-solid fa-eye fa-lg cursor-pointer float-right mt-1" @click="toggleMask"></i>
                  </span>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="small">Name :</label><span class="ml-2 small   mr-1" ref="nameOnCC">{{
    billingInformations.billing_methods[0].name_on_card }}</span><i
                  v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].name_on_card)" v-if="!isMasked"></i>


              </div>
              <div class="form-group mb-0">
                <label class="small">Billing Phone :</label>
                <span class="ml-2 small  mr-1" ref="nameOnCC">
                  {{ isMasked ? maskData(billingInformations.billing_methods[0].phone_number || '') :
    billingInformations.billing_methods[0].phone_number || '' }}
                </span>
                <i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyTextPhone(billingInformations.billing_methods[0].phone_number || '')"
                  v-if="!isMasked"></i>
              </div>
              <div class="form-group mb-0">
                <label class="small">Email :</label>
                <span class="ml-2 small  cursor-pointer mr-1">
                  {{ isMasked ? maskData(billingInformations.billing_methods[0].email) :
    billingInformations.billing_methods[0].email }}
                </span>
                <i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].email)" v-if="!isMasked"></i>
              </div>
              <div class="form-group mb-0">
                <label class="small">Bank Phone:</label><span class="ml-2 small cursor-pointer  mr-1"
                  ref="bankPhoneNumber">{{ isMasked ? maskData(billingInformations.billing_methods[0].card_phone || '')
    :
    billingInformations.billing_methods[0].card_phone || '' }}</span><i
                  v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyTextPhone(billingInformations.billing_methods[0].card_phone || '')" v-if="!isMasked"></i>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="small">Address :</label><span class="ml-2 small  cursor-pointer mr-1">{{
    isMasked ? maskData(billingInformations.billing_methods[0].address)
      : billingInformations.billing_methods[0].address

  }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].address)" v-if="!isMasked"></i>
              </div>
              <div class="col-md-12">
                <label class="small">Zip :</label><span class="ml-2 small  cursor-pointer mr-1">{{
    isMasked ? maskData(billingInformations.billing_methods[0].zip) :
      billingInformations.billing_methods[0].zip }}</span><i v-b-tooltip.click.right.delay="tooltipText"
                  class="fa-solid fa-copy cursor-pointer" @click="copyText(billingInformations.billing_methods[0].zip)"
                  v-if="!isMasked"></i>
              </div>

              <div class="col-md-12">
                <label class="small">City :</label><span class="ml-2 small  cursor-pointer mr-1">{{
    billingInformations.billing_methods[0].city
  }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].city)" v-if="!isMasked"></i>
              </div>

              <div class="col-md-12">
                <label class="small">State :</label><span class="ml-2 small  cursor-pointer mr-1">{{
    billingInformations.billing_methods[0].state
  }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].state)" v-if="!isMasked"></i>
              </div>
              <div class="col-md-12">
                <label class="small">Country :</label><span class="ml-2 small  cursor-pointer mr-1">{{
    billingInformations.billing_methods[0].country
  }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer"
                  @click="copyText(billingInformations.billing_methods[0].country)" v-if="!isMasked"></i>
              </div>
            </div>
          </div>
          <div class="col-md-2"
            v-if="billingInformations && billingInformations.json_amounts && billingInformations.json_amounts.length > 0">
            <label class="large">Total Amount :</label><span class="ml-2 large mr-1">{{ currencyCode }}
              {{
    billingInformations.json_amounts[0].company_charge ?? 0
  }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="tab-pane fade col-md-12" :class="selectedTab == 'two' ? 'show active' : ''" id="pills-profile"
        role="tabpanel"
        v-if="billingInformations && billingInformations.billing_methods && billingInformations.billing_methods[1]"
        aria-labelledby="pills-profile-tab">
        <div class="row p-0">
          <div class="col-md-4 bg-light" style="border-radius: 8px;">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-0 mt-1">
                  <label class="small">{{
                    billingInformations.billing_methods[1].card_type }} :</label>
                  <span class="ml-2 small  cursor-pointer mr-1"  ref="creditCardNumber">{{
                    maskSensitiveInfo1(billingInformations.billing_methods[1].card_number, 1) }}</span><i v-b-tooltip.click.right.delay="tooltipText"
                    class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].card_number)"></i>
                  <span class="ml-2 small  cursor-pointer" >{{
                    billingInformations.billing_methods[1].expired_date }}</span>
                  <span class="ml-2 small  cursor-pointer" >{{
                    isMasked1 ? maskData1(billingInformations.billing_methods[1].cvv) :
                    billingInformations.billing_methods[1].cvv }}</span>
                  <span v-if="isCreditCardPermission">
                    <i v-if="!isMasked1" class="fa-solid fa-eye-slash fa-lg cursor-pointer float-right mt-1"
                      @click="toggleMask1"></i>
                    <i v-else class="fa-solid fa-eye fa-lg cursor-pointer float-right mt-1" @click="toggleMask1"></i>
                  </span>
                </div>
              </div>
              <div class="form-group mb-0">
                <label class="small">Name :</label><span class="ml-2 small  cursor-pointer  mr-1" 
                  ref="nameOnCC">{{
                    billingInformations.billing_methods[1].name_on_card }}</span><i v-b-tooltip.click.right.delay="tooltipText"
                  class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].name_on_card)"></i>
              </div>
              <div class="form-group mb-0">
                <label class="small">Billing Phone :</label><span class="ml-2 small  cursor-pointer  mr-1"
                   ref="nameOnCC">{{
                    isMasked1 ? maskData1(billingInformations.billing_methods[1].phone_number) :
                    billingInformations.billing_methods[1].phone_number }}</span><i v-b-tooltip.click.right.delay="tooltipText"
                  class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].phone_number)"></i>
              </div>
              <div class="form-group mb-0">
                <label class="small">Email :</label><span  class="ml-2 small  cursor-pointer mr-1">{{
                  isMasked1 ? maskData1(billingInformations.billing_methods[1].email) :
                  billingInformations.billing_methods[1].email }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].email)"></i>
              </div>
              <div class="form-group mb-0">
                <label class="small">Bank Phone:</label><span class="ml-2 small  cursor-pointer  mr-1"
                   ref="bankPhoneNumber">32165498721</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" ></i>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <label class="small">Address :</label><span 
                  class="ml-2 small  cursor-pointer mr-1">{{
                    isMasked1 ? maskData1(billingInformations.billing_methods[1].address) :
                    billingInformations.billing_methods[1].address }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].address)"></i>
              </div>
              <div class="col-md-12">
                <label class="small">Zip :</label><span  class="ml-2 small  cursor-pointer mr-1">{{
                  isMasked1 ? maskData1(billingInformations.billing_methods[1].zip) :
                  billingInformations.billing_methods[1].zip }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].zip)"></i>
              </div>

              <div class="col-md-12">
                <label class="small">City :</label><span  class="ml-2 small  cursor-pointer mr-1">{{
                  billingInformations.billing_methods[1].city }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].city)"></i>
              </div>

              <div class="col-md-12">
                <label class="small">State :</label><span  class="ml-2 small  cursor-pointer mr-1">{{
                  billingInformations.billing_methods[1].state }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].state)"></i>
              </div>
              <div class="col-md-12">
                <label class="small">Country :</label><span 
                  class="ml-2 small  cursor-pointer mr-1">{{
                    billingInformations.billing_methods[1].country }}</span><i v-b-tooltip.click.right.delay="tooltipText" class="fa-solid fa-copy cursor-pointer" v-if="!isMasked1" @click="copyText(billingInformations.billing_methods[1].country)"></i>
              </div>
            </div>
          </div>
          <div class="col-md-2"
            v-if="billingInformations && billingInformations.json_amounts.cards && billingInformations.json_amounts.cards.length > 0">
            <label class="large">Total Amount :</label><span  class="ml-2 large mr-1">{{ currencyCode }}
              {{
                billingInformations.json_amounts.cards[1].company_charge
              }}</span>
          </div>
        </div>
      </div> -->

    </div>
    <!-- <div class="col-md-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-right" style="background-color: lightgreen; border-radius: 8px">
                        <div class="col-md-6">
                            <label class="mt-1 float-left pl-2" style="color: black text-center">TAF</label>
                        </div>
                        <div class="col-md-6">
                            <label class="pl-2" style="color: black">Status</label>
                            <select class="ml-2 p-1 pl-2" v-model="billingInfo.status" style="background: transparent;
                    appearance: none;
                    border: none;
                    outline: none;
                  ">
                                <option value="None">None</option>
                                <option value="Sent">Sent</option>
                                <option value="Received">Received</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div>
                        <div class="row">
                            <h4>&nbsp;&nbsp;</h4>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Sent :</label>
                                    <input v-model="billingInfo.taf_status" type="text" class="au-input form-control" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Attached :</label>
                                    <input v-model="billingInfo.attached" type="text" class="au-input form-control" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>CCV Type :</label>
                                    <select v-model="billingInfo.ccv_type" class="au-input form-control">
                                        <option value="CC Check">CC Check</option>
                                        <option value="CC+ID">CC+ID</option>
                                        <option value="Confrence call">Confrence call</option>
                                        <option value="CC+ID+Selfie">CC+ID+Selfie</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>CCV Status :</label>
                                    <select v-model="billingInfo.ccv_status" class="au-input form-control">
                                        <option value="Open">Open</option>
                                        <option value="Done">Done</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label>Approved by :</label>
                                    <input v-model="billingInfo.approved_by" type="text" class="au-input form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
    <!-- <div class="modal fade" id="paymet_detail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"> Payment Deatils</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div style="border: solid 1px lightgray;padding: 11px;border-radius: 5px;">
              <div class="row">
                <div class="col-md-4">
                  <span for="cpm">BT Price :</span>
                  <span class="ml-1">{{ billingInformations.json_amounts.company_amount }}</span>
                </div>
                <div class="col-md-4">
                  <span for="Tip">Tip :</span>
                  <span class="ml-1">{{ billingInformations.json_amounts.tip_amount }}</span>
                </div>
                <div class="col-md-4">
                  <span for="travelAssist">Travel Assist:</span>
                  <span class="ml-1">{{ billingInformations.json_amounts.travel_assist_cost }}</span>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-4">
                  <span>Airlines :</span>
                  <span v-for="(amountObj, index) in billingInformations.json_amounts.airlines_amounts" :key="index"
                    class="ml-1"> {{ amountObj.code }} : {{ amountObj.amount }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 " style="border: solid 1px lightgray;">
              <div class="row">
                <div style="padding-top: 5px;" class="col-md-6" v-if="billingInformations.billing_methods[0]">
                  <div>
                    <h5 class="text-center">Card 1</h5>
                    <div style="border: solid 1px lightgray;border-radius: 5px;">

                      <div class="ml-2 pt-2">
                        <span for="cpm"> {{ billingInformations.billing_methods[0].card_type }} : </span>
                        <span class="ml-1">{{ billingInformations.billing_methods[0].name_on_card }}</span>
                        <span class="ml-1"> {{ "**** **** **** " +
                          billingInformations.billing_methods[0].card_number.slice(-4) }}</span>
                      </div>
                      <div class="pl-2 pb-2">
                        <span> Amount : {{ billingInformations.billing_methods[0].amount }}</span>
                        <span class="ml-2"> Coefficient :{{ card.fee_coefficient }}</span>
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="padding-top: 5px;border: solid 1px lightgray;border-radius: 5px;padding-left: 10px;">
                      <div class="pb-2 pt-2">
                        <input type="text" id="btprice" name="btprice" placeholder="BT Price" class="au-input ml-1" />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="btairline" name="btairline" placeholder="BT Airline" class="au-input ml-1"
                          hidden />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="tip" name="tip" placeholder="Tip" class="au-input ml-1" />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="travelassist" name="travelassist" placeholder="Travel Assist"
                          class="au-input ml-1" />
                      </div>
                    </div>
                  </div>
                </div>
                <div style="padding-top: 5px;" class="col-md-6" v-if="billingInformations.billing_methods[1]">
                  <h5 class="text-center">Card 2</h5>
                  <div style="border: solid 1px lightgray;border-radius: 5px;">
                    <div class="ml-2 pt-2">
                      <span for="cpm"> {{ billingInformations.billing_methods[1].card_type }} : </span>
                      <span class="ml-1">{{ billingInformations.billing_methods[1].name_on_card }}</span>
                      <span class="ml-1"> {{ "**** **** **** " +
                        billingInformations.billing_methods[1].card_number.slice(-4) }}</span>
                    </div>
                    <div class="pl-2 pb-2">
                      <span> Amount : {{ billingInformations.billing_methods[1].amount }}</span>
                      <span class="ml-2"> Coefficient :{{ master.fee_coefficient }}</span>
                    </div>
                    <div>
                    </div>
                  </div>
                  <div>
                    <div style="padding-top: 5px;border: solid 1px lightgray;border-radius: 5px;padding-left: 10px;">
                      <div class="pb-2 pt-2">
                        <input type="text" id="btprice1" name="btprice1" placeholder="BT Price" class="au-input ml-1" />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="btairline" name="btairline" placeholder="BT Airline" class="au-input ml-1"
                          hidden />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="tip1" name="tip1" placeholder="Tip" class="au-input ml-1" />
                      </div>
                      <div class="pb-2">
                        <input type="text" id="travelassist1" name="travelassist1" placeholder="Travel Assist"
                          class="au-input ml-1" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button class="btn btn-success mt-2" @click="updateCkFee()">save</button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      billingInfo: {
        id: 0,
      },
      // ccdetails: false,
      clipboard: null,
      selectedCard: "",
      selectedTab: 'one',
      isMasked: true,
      isMasked1: true,
      tooltipText: '',
      card: {
        fee_coefficient: ''
      },
      master: {
        fee_coefficient: ''
      },
      json_amounts: {
        card1: {
          company_charge: null,
          airline_charge: null,
          tip_charge: null,
          travelassist_charge: null
        }
      }
    };
  },
  props: {
    billingInformations: [],
    currencyCode: String,
  },
  computed: {
    isCreditCardPermission() {
      const permission = this.hasCreditCardPermission();
      return permission;
    }
  },
  watch: {
    billingInformations: function (val) {
      if (val.billing_methods && val.billing_methods.length > 0) {
        this.billingInfo = val.billing_methods[0];
        this.selectedCard = this.billingInfo.card_number;
      } else {
        this.billingInfo = {};
      }
    },
  },
  mounted() {

    const elements = document.querySelectorAll('.fa-copy');
    elements.forEach((element) => {
      element.addEventListener('click', () => {
        const textToCopy = element.previousElementSibling.textContent.trim();
        this.copyText(textToCopy);
      });
    });


  },
  methods: {
    copyText(text) {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.tooltipText = 'Copied';
      setTimeout(() => {
        this.tooltipText = '';
      }, 2000);
    },
    copyTextPhone(text) {
      const spaceIndex = text.indexOf(' ');
      const textToCopy = spaceIndex !== -1 ? text.slice(spaceIndex + 1) : text;
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.tooltipText = 'Copied';
      setTimeout(() => {
        this.tooltipText = '';
      }, 2000);
    },
    hasCreditCardPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = 'sales-credit-card-details-preview';
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    toggleMask() {
      this.isMasked = !this.isMasked;
    },
    toggleMask1() {
      this.isMasked1 = !this.isMasked1;
    },
    maskData(data) {
      return '*'.repeat(data.length);
    },
    maskData1(data) {
      return '*'.repeat(data.length);
    },
    maskSensitiveInfo(info) {
      return this.isMasked ? '*'.repeat(info.length - 4) + info.slice(-4) : info;
    },
    maskSensitiveInfo1(info) {
      return this.isMasked1 ? '*'.repeat(info.length - 4) + info.slice(-4) : info;
    },
    // getCardBySlug() {
    //   const slug = this.billingInformations.billing_methods[0].card_type;

    //   this.axios
    //     .get(`/get-card-by-slug/${slug}`)
    //     .then((response) => {
    //       this.card = response.data.data;
    //     })
    //     .catch((error) => {
    // },
    // getMasterBySlug() {
    //   const slug = this.billingInformations.billing_methods[1].card_type;

    //   this.axios
    //     .get(`/get-card-by-slug/${slug}`)
    //     .then((response) => {
    //       this.master = response.data.data;
    //     })
    //     .catch((error) => {
    //     });
    // },
    updateCkFee() {
      let cardsArray = [];
      const id = this.billingInformations.id;
      const billingMethod = this.billingInformations.billing_methods[0].id;
      const coefficient = this.card.fee_coefficient;
      const jsonAmounts = this.billingInformations.json_amounts;
      const priceValue = document.getElementById("btprice").value;
      const tipValue = document.getElementById("tip").value;
      const travelAssistValue = document.getElementById("travelassist").value;

      cardsArray.push({
        id: billingMethod,
        ck_coefficient: coefficient,
        company_charge: priceValue,
        tip_charge: tipValue,
        travelassist_charge: travelAssistValue
      });

      if (this.billingInformations.billing_methods[1]) {
        const billingMethod2 = this.billingInformations.billing_methods[1].id;
        const priceValue2 = document.getElementById("btprice1").value;
        const tipValue2 = document.getElementById("tip1").value;
        const travelAssistValue2 = document.getElementById("travelassist1").value;
        cardsArray.push({
          id: billingMethod2,
          ck_coefficient: coefficient,
          company_charge: priceValue2,
          tip_charge: tipValue2,
          travelassist_charge: travelAssistValue2
        });
      }
      const amounts = {
        ...jsonAmounts,
        cards: cardsArray

      };
      this.axios
        .post('/update-ck-fee', { id, amounts })
        .then(response => {
        })
        .catch(error => {
        });
    },
    updateBillInfo() {
      this.billingInformations.billing_methods.forEach((element) => {
        if (element.card_number == this.selectedCard) {
          this.billingInfo = element;
        }
      });
    },
  },
};
</script>

<style scoped>
.w-35 {
  width: 35% !important;
}

.secondary {
  background-color: gray;
  color: white;
  font-weight: 600;
  font-size: large;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: hsl(0deg 0% 97.64%);
  background: var(--iq-light-primary);
}

.hidden-button {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.w-65 {
  width: 65% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.payment {
  color: aliceblue;
  padding-right: 3px;
  padding-left: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>
